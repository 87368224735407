<template>
    <div class="youtube-player-noise-parent">
        <canvas></canvas>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import YouTubePlayerText from './YouTubePlayerText.vue';

    export default {
        mounted() {
            const canvas = document.getElementsByTagName("canvas")[0];

            let imageData;
            let context;

            function resize(e) {
              canvas.width = window.innerWidth;
              canvas.height = window.innerHeight;
              context = canvas.getContext('2d');
              imageData = context.createImageData(canvas.width, canvas.height);
            }

            function redraw(t) {
              const pixel_count = imageData.data.length / 4;
              for (let pixel = 0; pixel < pixel_count; pixel++) {
                const v = (Math.random() * 255) >>> 0;
                imageData.data[4 * pixel + 0] = v;
                imageData.data[4 * pixel + 1] = v;
                imageData.data[4 * pixel + 2] = v;
                imageData.data[4 * pixel + 3] = 255;
              }
              context.putImageData(imageData, 0, 0);
              window.requestAnimationFrame(redraw);
            }

            console.clear();
            resize();
            window.addEventListener('resize', resize);
            window.requestAnimationFrame(redraw);

        }
    }
</script>

<style lang="scss" scoped>

    .youtube-player-noise-parent {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        // background: black;
        z-index: 1;
        opacity: 0.05;
        // background-blend-mode: overlay;

        // background-image: url(https://warzone.atlassian.net/wiki/download/attachments/4390919/scanlines.png);
        // background-size: cover;
    }

    //---------------------------------------------------------
    // PLAYER INNER (IFRAME PARENT)
    //---------------------------------------------------------

    canvas {
        background-color: black;
        display: block;
    }
</style>